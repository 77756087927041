import type { BootstrapData } from '../../types'
import type { SessionServiceAPI } from '@wix/thunderbolt-symbols'
import { ViewerPlatformEssentials } from '@wix/fe-essentials-viewer-platform'
import { BOOTSTRAP_DATA, SESSION_SERVICE, PLATFORM_ESSENTIALS } from './moduleNames'

function getBaseUrlForSSR(bootstrapData: BootstrapData): string {
	const externalUrl = bootstrapData.platformEnvData.location.externalBaseUrl
	return new URL(externalUrl).origin
}

function PlatformEssentials(bootstrapData: BootstrapData, sessionService: SessionServiceAPI) {
	const isSSR = bootstrapData.platformEnvData.window.isSSR

	const baseUrl = isSSR ? getBaseUrlForSSR(bootstrapData) : ''

	return new ViewerPlatformEssentials({
		conductedExperiments: {},
		isSSR,
		baseUrl,
		metaSiteId: bootstrapData.platformEnvData.location.metaSiteId,
		appsConductedExperiments: bootstrapData.essentials.appsConductedExperiments,
		getAppToken(appDefId) {
			return sessionService.getInstance(appDefId)
		},
	})
}

export default {
	factory: PlatformEssentials,
	deps: [BOOTSTRAP_DATA, SESSION_SERVICE],
	name: PLATFORM_ESSENTIALS,
}

import type { IModelsAPI } from '@wix/thunderbolt-symbols'
// import type { IViewerHandlers } from '../types'
import type { IEffectsTriggerApi } from '../../types'
import { EFFECTS_TRIGGER_API, MODELS_API, VIEWER_HANDLERS } from './moduleNames'

export const EffectsTriggerApiFactory = (modelsApi: IModelsAPI /* , { viewerHandlers, createViewerHandlers }: IViewerHandlers*/): IEffectsTriggerApi => {
	const activeEffectsMap = {} as { [compId: string]: Array<string> }
	const invalidateComponentEffects = (compId: string, effectNames: Array<string>) => {
		const availableEffects = new Set(modelsApi.getEffectsByCompId(compId))
		return Array.from(new Set(effectNames)).filter((effect) => availableEffects.has(effect))
	}
	return {
		init() {
			// const masterPageHandlers = createViewerHandlers('masterPage')
			// viewerHandlers.triggersAndReactions.registerToActiveEffectsChange((compId: string, list: Array<string>) => (activeEffectsMap[compId] = list))
			// masterPageHandlers.triggersAndReactions.registerToActiveEffectsChange((compId: string, list: Array<string>) => (activeEffectsMap[compId] = list))
		},
		createCompTriggerAndReactionsApi: (compId: string) => {
			return {
				getEffects: () => modelsApi.getEffectsByCompId(compId),
				getActiveEffects: () => activeEffectsMap[compId] || [],
				applyEffects: (...effectNames: Array<string>) => {
					const effects = invalidateComponentEffects(compId, effectNames)
					activeEffectsMap[compId] = [...(activeEffectsMap[compId] || []), ...effects]
					// viewerHandlers.triggersAndReactions.applyEffects(compId, effects)
				},
				removeEffects: (...effectNames: Array<string>) => {
					const effects = invalidateComponentEffects(compId, effectNames)
					activeEffectsMap[compId] = (activeEffectsMap[compId] || []).filter((effect) => !effects.includes(effect))
					// viewerHandlers.triggersAndReactions.removeEffects(compId, effects)
				},
				toggleEffects: (...effectNames: Array<string>) => {
					const effects = invalidateComponentEffects(compId, effectNames)
					const currentEffects = activeEffectsMap[compId] || []
					activeEffectsMap[compId] = [...currentEffects.filter((effect) => !effects.includes(effect)), ...effects.filter((effect) => !currentEffects.includes(effect))]
					// viewerHandlers.triggersAndReactions.toggleEffects(compId, effects)
				},
				removeAllEffects: () => {
					activeEffectsMap[compId] = []
					// viewerHandlers.triggersAndReactions.removeAllEffects(compId)
				},
			}
		},
	}
}

export default {
	factory: EffectsTriggerApiFactory,
	deps: [MODELS_API, VIEWER_HANDLERS],
	name: EFFECTS_TRIGGER_API,
}

import type { Language } from '@wix/thunderbolt-becky-types'
import { name } from './symbols'

export const captchaChallengeActions = {
	login: 'LOGIN',
	signup: 'SIGNUP',
} as const
export type CaptchaChallengeActions = typeof captchaChallengeActions[keyof typeof captchaChallengeActions]

export interface ICaptchaDialog extends Record<string, Function> {
	openCaptchaDialog(): Promise<string | null>

	withCaptchaChallengeHandler<T extends (recaptchaToken?: string | null) => any>(
		action: CaptchaChallengeActions,
		cb: T
	): Promise<ReturnType<T>>
}

export type CaptchaDialogProps = {
	language: Language
	onVerified: (token: string) => void
	onClose: () => void
}

export type AuthenticationWixCodeSdkHandlers = {
	[name]: ICaptchaDialog
}

export interface AuthenticationWixCodeSdkWixCodeApi {
	openCaptchaChallenge: ICaptchaDialog['openCaptchaDialog']
	withCaptchaChallengeHandler: ICaptchaDialog['withCaptchaChallengeHandler']
}

export type AuthenticationWixCodeSdkPageConfig = {}

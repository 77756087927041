import type { ConsentPolicyChangedHandler, IConsentPolicyManager } from '@wix/thunderbolt-symbols'
import type { ConsentPolicy, PolicyDetails, PolicyHeaderObject } from '@wix/cookie-consent-policy-client'
import type { BootstrapData } from '../../types'
import type { IViewerHandlers } from '../types'
import { BOOTSTRAP_DATA, CONSENT_POLICY_MANAGER, VIEWER_HANDLERS } from './moduleNames'

const ConsentPolicyManager = ({ viewerHandlers }: IViewerHandlers, { platformEnvData }: BootstrapData): IConsentPolicyManager => {
	let { details: consentPolicyDetails, header: consentPolicyHeaderObject } = platformEnvData.consentPolicy

	const clonePolicyDetails = (policyDetails: PolicyDetails) => ({
		...policyDetails,
		policy: {
			...policyDetails.policy,
		},
	})

	const clonePolicyHeaderObject = (policyHeaderObject: PolicyHeaderObject) => ({
		...policyHeaderObject,
	})

	const consentPolicyChangedHandlers: Array<ConsentPolicyChangedHandler> = []

	if (process.env.browser) {
		viewerHandlers.consentPolicy.registerToConsentPolicyUpdates((policyDetails: PolicyDetails, policyHeaderObject: PolicyHeaderObject) => {
			consentPolicyDetails = policyDetails
			consentPolicyHeaderObject = policyHeaderObject
			consentPolicyChangedHandlers.forEach((handler) => handler(clonePolicyDetails(policyDetails)))
		})
	}

	return {
		getDetails() {
			return clonePolicyDetails(consentPolicyDetails)
		},
		getHeader() {
			return clonePolicyHeaderObject(consentPolicyHeaderObject)
		},
		setPolicy(policy: ConsentPolicy) {
			return viewerHandlers.consentPolicy.setConsentPolicy(policy)
		},
		resetPolicy() {
			return viewerHandlers.consentPolicy.resetConsentPolicy()
		},
		onChanged(handler: ConsentPolicyChangedHandler) {
			consentPolicyChangedHandlers.push(handler)
		},
	}
}

export default {
	factory: ConsentPolicyManager,
	deps: [VIEWER_HANDLERS, BOOTSTRAP_DATA],
	name: CONSENT_POLICY_MANAGER,
}
